import { colors as baseColors, hexWithOpacity } from '@hummingbird/shared';

const colors = {
  ...baseColors,
  gray4: '#F4F3F5',
  primary: hexWithOpacity('#8800FF', 0.1),
  primaryFortress: '#D4EBFC',
  primaryMio: '#E3E9F8',
  primarySora: '#AFFFED',
} as const;

export default colors;
