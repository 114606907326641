import { Fortress, LogoLetters, Mio, Sora } from 'assets/icons';
import { colors } from 'theme';

type BrandingConfig = {
  primaryColor: string;
  intercomColor: string;
  logo: React.ReactElement;
};

const FORTRESS_ID = '0997eda8-1ed6-4181-afa7-16f3b99cd5d7';
const MIO_ID = 'b180a98f-794f-4752-8fa1-63aa93552e3b';
const SORA_ID = 'd4127f1f-f807-4c85-af4b-bd4a02adca87';

/**
 * Expandable mapper for specific clients configuration
 */
export const CLIENT_ID_TO_BRANDING_MAPPER: Record<string, BrandingConfig> = {
  [FORTRESS_ID]: {
    primaryColor: colors.primaryFortress,
    intercomColor: '#269AEF',
    logo: <Fortress height={33} width={131} />,
  },
  [MIO_ID]: {
    primaryColor: colors.primaryMio,
    intercomColor: '#758FDA',
    logo: <Mio />,
  },
  [SORA_ID]: {
    primaryColor: colors.primarySora,
    intercomColor: '#8800FF',
    logo: <Sora height={36} width={76} />,
  },
  default: {
    primaryColor: colors.primary,
    intercomColor: '#8800FF',
    logo: <LogoLetters />,
  },
};
